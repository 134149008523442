import Landing from './components/Landing';
const bgStyle = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:
    ' transparent linear-gradient(58deg, #00dc99 0%, #63b1cd 46%, #9d98ec 100%) 0% 0% no-repeat padding-box',
  opacity: 0.33,
};

function BackgroundGradient() {
  return <div style={bgStyle} />;
}

function App() {
  return (
    <>
      <BackgroundGradient />
      <Landing />
    </>
  );
}

export default App;
