import "./Landing.css";
import React, { useState, useEffect } from "react";
const Landing = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <div className="landing">
        <nav className="navbar">
          <div className="container flex-content">
            <div className="logo">
              <a href="/">
                <img src="./image/new_logo.png" alt="" />
              </a>
            </div>
            <p>
              <a href="mailto:hello@kwaba.ng" className="contact">
                hello@kwaba.ng
              </a>
            </p>
          </div>
        </nav>

        <div className="container small">
          <div className="landing-grid">
            <div className="landing-grid-content">
              <h1>Your journey to homeownership starts with us</h1>
              <p>
                Kwaba helps you achieve your dream of becoming a homeowner
                sooner rather than later.
              </p>
              {/* <button type="button" className="join-waitlist vrlps-trigger">
                Join waitlist
              </button> */}

              <button
                type="button"
                className="join-waitlist vrlps-trigger"
                onClick={toggleModal}
              >
                Join waitlist
              </button>

              {/* <button type="button" class="vrlps-trigger">Join our campaign!</button> */}

              <div className="app-coming-soon">
                <span>App coming soon</span>
                <div className="app-coming-soon-image">
                  <span href="/">
                    <img src="./image/play-store.png" alt="" />
                  </span>
                  <span href="/">
                    <img src="./image/ios-store.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="landing-grid-image">
              {/* <img src="./image/landing-img.png" alt="" /> */}
              <img src="./image/Mortgage.svg" alt="Kwaba" />
            </div>
          </div>
        </div>

        <div className={!modal ? "form-container hide" : "form-container"}>
          <div id="fd-form-611e32bfef01fdd7c79a00c5" className="t-form">
            <img
              src="./image/close.png"
              className="close-icon"
              onClick={() => setModal(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Landing;
